// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/header-countryicon.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".country_bg[data-v-9ed07434]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}.country_bg.cn_bg[data-v-9ed07434],.country_bg.ex_bg[data-v-9ed07434],.country_bg.sf_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 0}.country_bg.hk_bg[data-v-9ed07434]{width:0.42rem;height:0.16rem;background-position:0 -0.29rem}.country_bg.sg_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -0.57rem}.country_bg.kr_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -0.88rem}.country_bg.my_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -1.17rem}.country_bg.jp_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -1.49rem}.country_bg.us_bg[data-v-9ed07434]{width:0.42rem;height:0.16rem;background-position:0 -1.79rem}.country_bg.ru_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -2.12rem}.country_bg.gb_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -2.38rem}.country_bg.th_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -2.68rem}.country_bg.vn_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -2.97rem}.country_bg.au_bg[data-v-9ed07434]{width:0.42rem;height:0.16rem;background-position:0 -3.29rem}.country_bg.fr_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -3.58rem}.country_bg.de_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -3.88rem}.country_bg.id_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -4.18rem}.country_bg.ph_bg[data-v-9ed07434]{width:0.24rem;height:0.16rem;background-position:0 -4.48rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
