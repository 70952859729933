
export default {
  props: {
    iconClass: {
      type: String,
      default() {
        return ''
      },
    },
    site: {
      type: String,
      default() {
        return ''
      },
    },
  },
}
